import styled from "@emotion/styled";

export const Container = styled.div`
  position: absolute;
  top: 90px;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;


export const IconContainer = styled.div`
  width: 100px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  font-size: 50px;
  justify-content: center;
  align-items: center;
  color: green;
`;

export const ActionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;
export const SquareButton = styled.div`
  min-width: 120px;
  height: 120px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #3f51b5;
  color: white;
`;

export const Label = styled.label`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`;
