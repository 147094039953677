import React, { useEffect, useState } from "react";
import { Routes, UnauthRoutes } from "./Routes";
import Header from "./components/Header";
import { Alert, CssBaseline, Snackbar, Slide } from "@mui/material";
import styled from "@emotion/styled";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { installPrompt, loadingState, snackbar, userState } from "./states";
import { db } from "./services/db";
import LoadingProgressiveDialog from "./components/LoadingProgressiveDialog";
import InstallPopup from './components/InstallPopup';

const themeLight = createTheme({
  palette: {
    background: {},
  },
});

const Container = styled.div((props) => ({
  marginTop: "90px",
  padding: "0 20px",
}));

export default function Main() {
  const [snack, setSnack] = useRecoilState(snackbar);
  const [user, setUser] = useRecoilState(userState);
  const loadingModal = useRecoilValue(loadingState);
  const [isLoading, setIsLoading] = useState(true);
  const setInstall = useSetRecoilState(installPrompt);


  useEffect(() => {
    const cb = (event) => {
      event.preventDefault();
      setInstall({ prompt: true, event });
      window.defferedPrompt = event;
    }
    window.addEventListener("beforeinstallprompt", cb);
    async function loadUser() {
      const userDb = (await db.user.toArray())[0];
      if (userDb) setUser({ token: userDb.token, user: userDb.user });
      setIsLoading(false);
    }
    loadUser();
    return () => window.removeEventListener("beforeinstallprompt", cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack((prev) => ({ ...prev, show: false }));
  };

  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      {!isLoading && (
        <>
          <Header />
          <Container>
            {user.token ? (
              <Routes />
            ) : (
              <UnauthRoutes />
            )}
          </Container>
          <Snackbar
            open={snack.show}
            autoHideDuration={3000}
            onClose={handleClose}
            TransitionComponent={Slide}
          >
            <Alert
              onClose={handleClose}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.message}
            </Alert>
          </Snackbar>
          <LoadingProgressiveDialog
            open={loadingModal.show}
            title={loadingModal.message}
          />
          <InstallPopup />
        </>
      )}
    </ThemeProvider>
  );
}
