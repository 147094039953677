import { Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Label, SquareButton } from "../styles/Container";
import AddIcon from "@mui/icons-material/AddAPhoto";
import { fileToBlobUrl } from "../../utils/Files";


const Img = styled.img`
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  min-height: 200px;
  margin-right: 10px;
  border:solid 2px black;
  object-fit: cover;
`;
// const request = { foto_box: true, foto_central: false, foto_adesivo: false, foto_repetidor: true }

const textLabel = {
    foto_box: "BOX",
    foto_repetidor: 'REPETIDOR',
    foto_central: "CENTRAL",
    foto_adesivo: "ADESIVOS"
}

export default function Check({ request, finish, continueRequest, onStep }) {
    const keys = Object.keys(request).filter(key => !!request[key]);
    const [currentKey, setCurrentKey] = useState(0);
    const [requestImg, setRequestImg] = useState(false);
    const ref1 = useRef(null);
    useEffect(() => {
        async function reloadImages() {
            let newRequestImg = structuredClone(continueRequest ?? {});
            for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                if (Array.isArray(newRequestImg[key])) {
                    await Promise.all(newRequestImg[key].map(async (img, idx) => {
                        const url = await fileToBlobUrl(img.file);
                        newRequestImg[key][idx] = { url, file: img.file };
                    }));
                }
            }
            setRequestImg({ ...newRequestImg })
        }
        if (keys.length <= 0) {
            next();
        }
        reloadImages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function selectFiles(event, id) {
        const files = event.target.files;
        if (files[0]) {
            const url = await fileToBlobUrl(files[0]);
            setRequestImg(prevImg => {
                let newValue = structuredClone(prevImg);
                const key = keys[id];
                if (newValue[key] === undefined)
                    newValue[key] = []
                if (Array.isArray(newValue[key])) {
                    newValue[key].push({ url, file: files[0] });
                } else if (newValue[key] === true) {
                    newValue[key] = [{ url, file: files[0] }];
                }
                return newValue;
            })

        }
    }
    function changeImage(id) {
        if (window.confirm("Deseja remover a foto?")) {
            let mBoxImg = requestImg[keys[currentKey]];
            mBoxImg = mBoxImg.filter((x, idx) => idx !== id);
            setRequestImg(prev => {
                let newValue = structuredClone(prev);
                newValue[keys[currentKey]] = mBoxImg;
                return newValue;
            }
            );
        }
    }

    function next() {
        if (currentKey + 1 >= keys.length) {
            finish(requestImg);
        } else {
            onStep(requestImg);
            setCurrentKey(currentKey + 1);
        }
    }

    return <div style={{ maxWidth: 300, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography>ENVIE A FOTO {textLabel[keys[currentKey]]}</Typography>
        <Button>VER EXEMPLO</Button>
        {
            requestImg && requestImg[keys[currentKey]]?.map((img, idx) => {
                return (
                    <Img
                        key={idx}
                        src={img.url}
                        alt={keys[currentKey]}
                        onClick={() => { changeImage(idx) }}
                    />)
            })
        }
        {
            (
                <SquareButton style={{ width: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <Label htmlFor="upload">
                        <AddIcon />
                        <input
                            ref={ref1}
                            type={"file"}
                            id="upload"
                            style={{ display: "none" }}
                            onChange={(event) => selectFiles(event, currentKey)}
                            accept="image/*"
                        />
                    </Label>
                </SquareButton>
            )
        }
        {Array.isArray(requestImg[keys[currentKey]]) && (requestImg[keys[currentKey]].length > 0) && <Button style={{ marginTop: 15 }} variant="contained" onClick={next}>
            PROXIMO
        </Button>}
    </div>
}