import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";

export default function ModalItem({ open, onClose, addMaterial, items }) {
    const [item, setItem] = useState();
    const [itemName, setItemName] = useState();
    const [itemQnt, setItemQnt] = useState();
    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Material Utilizado</DialogTitle>
        <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="item-name">Nome</InputLabel>
                    <Select
                        labelId="item-name"
                        value={item}
                        label="Nome"
                        onChange={(e) => { setItem(e.target.value); setItemName(items.filter(x => x.id === e.target.value)[0].nome) }}
                    >
                        {items?.map(item =>
                            <MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, maxWidth: 100 }}>
                    <TextField
                        id="outlined-basic"
                        label="Quantidade"
                        variant="outlined"
                        type="number"

                        value={itemQnt}
                        onChange={(e) => { setItemQnt(parseInt(e.target.value)) }}
                    />
                </FormControl>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancelar</Button>
            <Button onClick={() => addMaterial(item, itemQnt, itemName)} autoFocus>
                Adicionar
            </Button>
        </DialogActions>
    </Dialog>)
}