import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import Construction from "@mui/icons-material/Construction";
import AccountCircle from "@mui/icons-material/AccountCircle";

function BottomNav({ value, setValue }) {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction label="OS" icon={<Construction />} />
        {/* <BottomNavigationAction label="Em aberto" icon={<Restore />} /> */}
        {/* <BottomNavigationAction label="Finalizadas" icon={<TaskAlt />} /> */}
        <BottomNavigationAction label="Perfil" icon={<AccountCircle />} />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;
