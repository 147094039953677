import { closeOS, getFeed, loadOSOpends, loadProducts, openOS } from "../services/api";
import { db } from "../services/db";

export async function loadFeed() {
  const response = await getFeed();
  if (response) {
    await db.os_feed.bulkPut(response);
    return response;
  } else {
    const data = await db.os_feed.toArray();
    return data;
  }
}

export async function osOpen(os) {

  const existingOS = await db.os_open.get(os.id);
  if (existingOS) {
    const materials = existingOS.materials
    await db.os_open.put({ ...existingOS, ...os, materials });
  }
  if (!existingOS) {
    const response = await openOS(os.id);
    if (response) {
      const osOld = { ...os, check_in_date: os.props.check_in_date ?? null, client_cargo: os.props.client_cargo ?? null, client_name: os.props.client_name ?? null }
      await db.os_open.put(osOld);
      await db.os_feed.put(osOld);
      const products = await loadProducts();
      if (products) {
        await db.products.clear();
        await db.products.bulkAdd(products);
      }
      return true;
    } else {
      return false;
    }
  }
  return true;
}

export async function loadOsOpened() {
  const response = await loadOSOpends();
  if (response) {
    for (let i = 0; i < response.length; i++) {
      const element = response[i];
      const os = await db.os_open.get(element.id);
      if (os && element.id === os.id) {
      } else {
        await db.os_open.put(element);
      }
    }
  }
  const data = await db.os_open.toArray();
  return data;
}

export async function loadOSClosed() {
  const response = await db.os_closed.toArray();
  return response;
}

export async function osClose(OS) {
  const os = OS;
  os.closed_at = new Date().toJSON();
  await db.os_open.put(os);
}

export async function osSend(OS) {
  const os = OS;
  const formData = new FormData();
  for (let i = 0; i < OS.media.length; i++) {
    const file = OS.media[i].file;
    console.log(OS.media[i].file);
    console.log(`image[${i}]`, file);
    formData.append(`image[${i}]`, file);
  }
  for (let i = 0; i < OS.check_in?.length; i++) {
    formData.append(`check_in_id[${i}]`, OS.check_in[i].id);
    formData.append(`check_in_f[${i}]`, OS.check_in[i].file);
  }
  for (let i = 0; i < OS.check_out?.length; i++) {
    formData.append(`check_out_id[${i}]`, OS.check_out[i].id);
    formData.append(`check_out_f[${i}]`, OS.check_out[i].file);
  }
  const requests = Object.keys(OS.request ?? {});
  for (let i = 0; i < requests?.length; i++) {
    OS.request[requests[i]].forEach((file, index) => {
      formData.append(`request.${requests[i]}[${index}]`, file.file);
    });
    // formData.append(`request.${requests[i]}[${requests[i]}]`, OS.check_out[i].file);
  }
  console.log(formData)
  formData.append("closed_at", os.closed_at);
  formData.append("client_name", os.client_name);
  formData.append("client_cargo", os.client_cargo);
  formData.append("check_in_date", os.check_in_date);
  formData.append("check_out_date", os.check_out_date);
  formData.append("resolution", os.resolution);
  formData.append("materials", JSON.stringify(os.materials));
  console.log("Materias", JSON.stringify(os.materials));
  formData.append("signature", os.signature);
  let response = await closeOS(os.id, formData);
  if (response) {
    return true;
  }
  return false;
}
