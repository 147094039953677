import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import ConstructionIcon from "@mui/icons-material/Construction";
import WarningAmber from '@mui/icons-material/WarningAmber';
import Done from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import { CardHeader } from "@mui/material";

import styled from "@emotion/styled";

const CardStyled = styled(Card)`
  margin: 20px;
`;

const Title = styled(Typography)`
  font-size: 1rem;
`;

export default function OSCard({ os, onOpen }) {
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options).replace(',', '');
  };

  return (
    <CardStyled >
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: os.checked ? '#03d203' : os.closed_at ? '#3d85c6' : os.opened_at ? '#ff6700' : 'gray' }}>
            {(os.checked ? <Done /> : os.closed_at ? <ReplayIcon /> : os.opened_at ? <ConstructionIcon /> : <WarningAmber />)}
          </Avatar>
        }
        title={`OS: ${os.id} ${os.installation ? (' - INSTALAÇÃO (' + os.box + ' Box)') : ''} (` + (os.checked ? 'FINALIZADA' : os.closed_at ? 'AGUARDANDO APROVAÇÃO' : os.opened_at ? 'ABERTA' : 'PENDENTE') + ')'}
        subheader={formatDate(os.created_at)}
      />
      <CardContent>
        <Title fontWeight={"bold"} marginTop={-2}>
          CLIENTE:
        </Title>
        <Title>{os.customer.nome_fantasia}</Title>
        <Title fontWeight={"bold"} marginTop={1}>
          DESCRIÇÃO:
        </Title>
        <Title>{os.description}</Title>
        <Title fontWeight={"bold"} marginTop={1}>
          DISTÂNCIA:
        </Title>
        <Title>{os.distance}</Title>
      </CardContent>
      <CardActions>
        {(!os.checked && !os.closed_at && onOpen) && (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              onOpen();
            }}
          >
            {os.opened_at ? "VER OS" : "ABRIR OS"}
          </Button>
        )}

        <Button
          size="small"
          color="secondary"
          onClick={() => {
            window.open(
              `https://maps.google.com/maps?daddr=${os.customer.lat},${os.customer.lng}`
            );
          }}
        >
          LOCALIZAÇÃO
        </Button>
      </CardActions>
    </CardStyled>
  );
}
