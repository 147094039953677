import { Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Label, SquareButton } from "../styles/Container";
import AddIcon from "@mui/icons-material/AddAPhoto";
import { fileToBlobUrl } from "../../utils/Files";
import { Modal, Box } from "@mui/material";
import imgBox from '../../assets/box.jpg';
import CloseIcon from "@mui/icons-material/Close";

const Img = styled.img`
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  min-height: 200px;
  margin-right: 10px;
  border:solid 2px black;
  object-fit: cover;
`;
export default function Installation({ os, finish, amount }) {
    const maxBox = (amount >= 0) ? amount : (os.installation ? os.box : os.posto?.numero_box)
    const [currentBox, setCurrentBox] = useState(1);
    const [boxImg, setBoxImg] = useState([]);
    const ref1 = useRef(null);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (maxBox === 0)
            next();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function selectFiles(event, id) {
        // setIsLoading(true);
        console.log('ad')
        const files = event.target.files;
        console.log(files[0]);
        if (files[0]) {
            const url = await fileToBlobUrl(files[0]);
            setBoxImg(prev => ([...prev, {
                id,
                url,
                file: files[0]
            }]))
            console.log({
                id,
                url
            })
        }
        // setData(toAdd);
        // setIsLoading(false);
    }
    function changeImage(id) {
        if (window.confirm("Deseja trocar a foto?")) {
            let mBoxImg = boxImg;
            mBoxImg = mBoxImg.filter(x => x.id !== id);
            setBoxImg(mBoxImg);
        }
    }

    function next() {
        if (currentBox + 1 > maxBox) {
            finish(boxImg);
        } else {
            setCurrentBox(currentBox + 1);
        }
    }

    return <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography>ENVIE A FOTO DO BOX</Typography>
        <Typography>{currentBox}</Typography>
        <Button onClick={handleOpen}>VER EXEMPLO</Button>
        {boxImg.filter(x => x.id === currentBox).map((img, idx) => (
            <Img
                key={idx}
                src={img.url}
                alt={img.name}
                onClick={() => { changeImage(img.id) }}
                style={{ margin: '0 auto' }} // Center the image
            />
        ))}
        {
            !(boxImg[currentBox - 1]) && (
                <SquareButton style={{ width: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden', marginRight: 0 }}>
                    <Label htmlFor="upload">
                        <AddIcon />
                        <input
                            ref={ref1}
                            type={"file"}
                            id="upload"
                            style={{ display: "none" }}
                            onChange={(event) => selectFiles(event, currentBox)}
                            accept="image/*"
                        />
                    </Label>
                </SquareButton>
            )
        }
        {(boxImg[currentBox - 1]) && <Button style={{ marginTop: 15 }} variant="contained" onClick={next}>
            PROXIMO
        </Button>}

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 20,
                    left: 30,
                    right: 30,
                    bottom: 20,
                    // transform: 'translate(-50%, -50%)',
                    width: '90%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    justifyContent: 'center', // Center the image inside the modal
                    alignItems: 'center' // Center the image inside the modal
                }}
            >
                <Button onClick={handleClose} style={{ position: 'absolute', top: 10, right: 10 }}><CloseIcon /></Button>
                <img src={imgBox} alt="Box" style={{ height: '100%' }} />
            </Box>
        </Modal>
    </div>

}