import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { DeleteOutlined, Logout } from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import { logout } from "../controllers/AuthController";
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, userState } from "../states";
import { dbClear } from "../services/db";

const Container = styled.div`
  padding: 10px 0;
  text-align: center;
`;
export default function Profile() {
  const [, setIsLoadingModal] = useRecoilState(loadingState);
  const user = useRecoilValue(userState)
  async function doLogout() {
    await logout();
    window.location = "/";
  }
  async function clear() {
    setIsLoadingModal({
      show: true,
      message: "Limpando",
    });
    await dbClear();
    setIsLoadingModal((prev) => ({ ...prev, show: false }));
  }
  return (
    <Paper>
      <Container>
        <span>Olá, {user.user.name}!</span>
      </Container>
      <MenuList>
        <MenuItem onClick={clear}>
          <ListItemIcon>
            <DeleteOutlined />
          </ListItemIcon>
          <ListItemText>Limpar Cache</ListItemText>
        </MenuItem>
        <MenuItem onClick={doLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
